// ✅ Vue imports
import { ref } from 'vue';

// 📦 External packages imports
import axios from 'axios';

// 🏗️ composable imports
import { hasPermission } from '@/Redesign/directives/guard';

// 👨‍💻 Internal packages imports

// 🌏 Global variables
const user = ref(null);
const loadingUser = ref(false);
const errorUser = ref(null);
const users = ref([]);
const loadingUsers = ref(false);
const errorUsers = ref(null);
const loadingImpersonate = ref(false);
const errorImpersonate = ref(null);
const isInviteUserLoading = ref(false);
const loadingDeleteUser = ref(false);

export default function useUser() {
  // 🔄 Functions
  const getMyUserInfo = async () => {
    loadingUser.value = true;
    try {
      const response = await axios.get('/user/me');

      user.value = response.data.user;
      return response.data.user;
    } catch (error) {
      errorUser.value = error;
      throw new Error(error.message);
    } finally {
      loadingUser.value = false;
    }
  };

  const getAllUsers = async () => {
    const hasPermissionToList = await hasPermission('organisation.user.list.view');
    if (await !hasPermissionToList) {
      return;
    }
    loadingUsers.value = true;
    try {
      const response = await axios.get('/users/list');
      users.value = await response.data.users;
      return response.data.users;
    } catch (error) {
      errorUsers.value = error;
      throw new Error(error.message);
    } finally {
      loadingUsers.value = false;
    }
  };

  const getUser = async (userId) => {
    const hasPermissionToView = await hasPermission('admin.user.list.view');
    if (await !hasPermissionToView) {
      return;
    }
    loadingUser.value = true;
    try {
      const response = await axios.get(`/users/${userId}`);
      return response.data.user;
    } catch (error) {
      errorUser.value = error;
      throw new Error(error.message);
    } finally {
      loadingUser.value = false;
    }
  };

  const inviteUser = async (email, name, organisationId) => {
    const hasPermissionToView = await hasPermission('admin.user.add');
    if (await !hasPermissionToView) {
      return;
    }
    isInviteUserLoading.value = false;
    try {
      const response = await axios.post('/invitation/new', {
        email: email,
        name: name,
        organisation_id: parseInt(organisationId, 10),
      });
      await getAllUsers();
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    } finally {
      isInviteUserLoading.value = false;
    }
  };

  const impersonateUser = async (userId) => {
    const hasPermissionToImpersonate = await hasPermission(
      'navigation.settings.menu.impersonate_user',
    );
    if (await !hasPermissionToImpersonate) {
      return;
    }
    loadingImpersonate.value = true;
    try {
      await axios.get(`/impersonate/${userId}`);
      window.location.href = '/';
    } catch (error) {
      errorImpersonate.value = error;
      throw new Error(error.message);
    } finally {
      loadingImpersonate.value = false;
    }
  };

  const updateUser = async (data) => {
    const hasPermissionToUpdate = await hasPermission('admin.user.edit');
    if (await !hasPermissionToUpdate) {
      return;
    }
    loadingUser.value = true;
    try {
      const response = await axios.put(`/users`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    } finally {
      loadingUser.value = false;
    }
  };

  const isImpersonating = async () => {
    /*const hasPermissionToImpersonate = await hasPermission(
      'navigation.settings.menu.impersonate_user',
    );
    if (await !hasPermissionToImpersonate) {
      return;
    }*/
    const response = await axios
      .get('/impersonating')
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return false;
      });
    return response;
  };

  const leaveImpersonation = async () => {
    try {
      await axios.get('/impersonate-leave');
      window.location.href = '/';
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const deleteUser = async (userId) => {
    const hasPermissionToList = await hasPermission('admin.user.delete');
    if (await !hasPermissionToList) {
      return;
    }
    loadingDeleteUser.value = true;
    try {
      const response = await axios.delete(`/users/${userId}`);
      user.value = null;
      return response;
    } catch (error) {
      errorUsers.value = error;
      throw new Error(error.message);
    } finally {
      loadingDeleteUser.value = false;
    }
  };

  return {
    // 🔄 Variables
    user,
    users,
    loadingUser,
    errorUser,
    isInviteUserLoading,
    loadingUsers,
    loadingDeleteUser,

    // 🔄 Functions
    getMyUserInfo,
    getAllUsers,
    getUser,
    updateUser,
    inviteUser,
    impersonateUser,
    isImpersonating,
    leaveImpersonation,
    deleteUser,
  };
}
